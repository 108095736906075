import React from "react";
import { Service, ServiceInfo } from "./Service";
import { ContainerColour, DiagonalContainer, ContainerSize } from "../../layout/DiagonalContainer";
import { Separator } from "../../layout/Separator";
import { useState } from "react";
import Img from "gatsby-image";

interface Props {
    serviceData: ServiceData;
}

export type ServiceData = {
    title: string,
    services: ServiceInfo[],
}

export enum ServiceState {
    GAME = "game-development",
    SOFTWARE = "software-development",
}

export const OurServices: React.FC<Props> = ({ serviceData }) => {
    const [gameDevOpen, setGameDevOpen] = useState<boolean>(true);
    const [softwareDevOpen, setSoftwareDevOpen] = useState<boolean>(false);

    const services = serviceData.services;

    const setGameDev = () => {
        setGameDevOpen(true);
        setSoftwareDevOpen(false);
    }
    const setSoftwareDev = () => {
        setSoftwareDevOpen(true);
        setGameDevOpen(false);
    }

    const generateServices = () => {
        const serviceElements: JSX.Element[] = [];
        for (let i = 0; i < services.length; ++i) {

            const element = <Service
                key={`home-service-${services[i].title}-${i}`}
                service={services[i]}
                isOpen={i === 0 ? gameDevOpen : softwareDevOpen}
                onClick={i === 0 ? setGameDev : setSoftwareDev}
            />
            serviceElements.push(element);
            if (i === 0) {
                serviceElements.push(<Separator key="home-service-separator" opacity={0.2} />)
            }
        }

        return serviceElements;
    }

    const renderFeaturedImage = () => {
        if (gameDevOpen) {
            return <Img fluid={services[0].featuredImage} alt={"Nana Nutmeg Praying"} />
        }
        if (softwareDevOpen) { // TODO need featured software dev image
            return <Img fluid={services[1].featuredImage} alt={"Nana Nutmeg Praying"} />
        }
        return <></>;
    }

    return (
        <DiagonalContainer id="OurServices" size={ContainerSize.LARGE} colour={ContainerColour.ORANGE} className="services-container" hasRips={false}>
            <div className="featured-image large-show" data-sal="slide-right">
                {renderFeaturedImage()}
            </div>
            <div className="service-info">
                <h2 className="title" data-sal="fade">OUR<br></br>SERVICES</h2>
                <div className="services">
                    {generateServices()}
                </div>
            </div>
            <div className="featured-image small-show">
                {renderFeaturedImage()}
            </div>
        </DiagonalContainer>
    )
}