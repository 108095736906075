import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout";
import { ServiceData } from "../components/sections/index/OurServices";
import { WorkData, OurWork } from "../components/sections/index/OurWork";
import { OurServices } from "../components/sections/index/OurServices";
import { Navigation } from "../components/navigation/Navigation";
import Img from 'gatsby-image'
import { HeaderSize, ParallaxHeader } from "../components/layout/ParallaxHeader";
import { HeaderInfo } from "../components/layout/HeaderInfo";
import { Hiring } from "../components/sections/index/Hiring";
import { HeaderImageContainer } from "../components/layout/HeaderImageContainer";
import { indexPageData } from "../content/pageData/IndexData";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        orangeDragonMobile: {
            childImageSharp: any;
        }
        orangeDragon: {
            childImageSharp: any;
        }
        greenDragon: {
            childImageSharp: any;
        }
        rrfbCard: {
            childImageSharp: any;
        }
        rrCard: {
            childImageSharp: any;
        }
        nana: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const title = indexPageData.title;
    const orangeDragonMobile = data.orangeDragonMobile.childImageSharp.fluid;
    const orangeDragon = data.orangeDragon.childImageSharp.fluid;
    const greenDragon = data.greenDragon.childImageSharp.fluid;
    const nana = data.nana.childImageSharp.fluid;

    const orangeDragonSources = [
        orangeDragonMobile,
        {
            ...orangeDragon,
            media: `(min-width: 768px)`,
        }
    ]

    const workData: WorkData = { work: indexPageData.workData.work };

    for (const item of workData.work) {
        item.image.src = data[item.image.name].childImageSharp.fluid;
    }

    const serviceData: ServiceData = { title: indexPageData.serviceData.title, services: indexPageData.serviceData.services }
    serviceData.services[0].featuredImage = nana;
    serviceData.services[1].featuredImage = nana;

    return (
        <Layout description={data.site.siteMetadata.description} keywords={indexPageData.keywords} pageTitle={indexPageData.pageTitle}>
            <Navigation />
            <ParallaxHeader size={HeaderSize.LARGE}>
                <HeaderInfo title={title} ctaLabel={"Learn More"} ctaLink={"#OurServices"} ctaAriaLabel={"Learn more about our services"} />
            </ParallaxHeader>
            <HeaderImageContainer>
                <Img className="orange-dragon" fluid={orangeDragonSources} alt={`Orange Viking Dragon`} />
                <Img className="green-dragon" fluid={greenDragon} alt={`Green Dragon`} />
            </HeaderImageContainer>
            <OurWork workData={{ title: indexPageData.workData.title, buttonLabel: indexPageData.workData.buttonLabel, work: workData.work }} />
            <OurServices serviceData={serviceData} />
            <Hiring />
        </Layout>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        orangeDragonMobile: file(relativePath: { eq: "assets/images/index/orange_dragon_small.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        orangeDragon: file(relativePath: { eq: "assets/images/index/orange_dragon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp

                }
            }
        }
        greenDragon: file(relativePath: { eq: "assets/images/index/green_dragon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp

                }
            }
        }
        rrCard: file(relativePath: { eq: "assets/images/work/rr1_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrfbCard: file(
            relativePath: { eq: "assets/images/work/rrfb_card.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        
        nana: file(relativePath: { eq: "assets/images/index/nana.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`